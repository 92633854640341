import './styles/App.css';

import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import myEpicNft from './utils/MyEpicNFT.json';

import { SketchPicker } from 'react-color';
import FadeLoader from 'react-spinners/FadeLoader';

const CONTRACT_ADDRESS = '0x12Be5BE45eee76dA4Df3e3076cEf58E7b356f3e4';

const App = () => {
  const [currentAccount, setCurrentAccount] = useState('');
  const [color, setColor] = useState('#35aee2');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert('Get MetaMask!');
        return;
      }

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      console.log('Connected', accounts[0]);
      setCurrentAccount(accounts[0]);

      // Setup listener! This is for the case where a user comes to our site
      // and connected their wallet for the first time.
      setupEventListener();
    } catch (error) {
      console.log(error);
    }
  };

  // Setup our listener.
  const setupEventListener = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, myEpicNft.abi, signer);

        connectedContract.on('NewEpicNFTMinted', (from, tokenId) => {
          setMessage(`Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea. Here's the link: <a href="https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}" target="_BLANK">https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}</a>`);
        });
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const askContractToMintNft = async () => {
    try {
      const { ethereum } = window;

      if (ethereum && color !== '') {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, myEpicNft.abi, signer);

        let nftTxn = await connectedContract.makeAnEpicNFT(color);

        console.log('Mining...please wait.');
        setLoading(true);
        await nftTxn.wait();
        console.log(nftTxn);
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
        setLoading(false);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes('This color is already minted')) {
        setMessage('This color is already minted');
      } else {
        setMessage(error);
      }
    }
  };

  useEffect(() => {
    const checkIfWalletIsConnected = async () => {
      const { ethereum } = window;

      if (!ethereum) {
        console.log('Make sure you have metamask!');
        return;
      } else {
        console.log('We have the ethereum object', ethereum);
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log('Found an authorized account:', account);
        setCurrentAccount(account);

        setupEventListener();
      } else {
        console.log('No authorized account found');
      }
    };

    checkIfWalletIsConnected();
  }, []);

  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button" style={{ '--color': color, '--inverted': invertColor(color) }}>
      Connect to Wallet
    </button>
  );

  const renderMintUI = () => (
    <div>
      <div className="picker">
        <SketchPicker color={color} onChangeComplete={(value) => setColor(value.hex)} />
      </div>

      {!loading && (
        <div>
          <button onClick={askContractToMintNft} className="cta-button connect-wallet-button" style={{ '--color': color, '--inverted': invertColor(color) }}>
            Mint NFT
          </button>

          <p className="information">Use Rinkeby, not the Mainnet!</p>
        </div>
      )}

      {loading && <FadeLoader color={color} size={30} />}
      {message !== '' && <p className="message" dangerouslySetInnerHTML={{ __html: message }}></p>}
    </div>
  );

  function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
  }

  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text" style={{ '--color': color, '--inverted': invertColor(color) }}>
            Owner of the color.
          </p>
          <p className="sub-text">Create here an unique NFT based on your favorite colors.</p>
          <p className="sub-sub-text">Each color has one owner. So be quick and mint your favorite color before someone else does!</p>
          {currentAccount === '' ? renderNotConnectedContainer() : renderMintUI()}
        </div>
      </div>
    </div>
  );
};

export default App;
